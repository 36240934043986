import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import MainLayout from './components/layout/MainLayout.vue'
import BudgetLayout from './components/layout/BudgetLayout.vue'
import AuthFormLayout from './components/layout/AuthFormLayout.vue'
import ClosingLayout from './components/layout/ClosingLayout.vue'

// Pages
import LoginPage from './pages/auth/LoginPage.vue'
import AdminMain from './pages/admin/AdminMain.vue'
import MngInout from './pages/manage/MngInout.vue'
import MngBudgetDetailList from './pages/manage/MngBudgetDetailList.vue'
import MngBudgetList from './pages/manage/MngBudgetList.vue'
import MngClosing from './pages/manage/MngClosing.vue'

import MngRptNewPage from './pages/manage/MngRptNewPage.vue'

import indexPage from './pages/indexPage.vue'
import MemberList from './pages/manage/MemberList.vue'

import TestOne from './pages/test/TestOne.vue'
import TestTwo from './pages/test/TestTwo.vue'
import EtcConfig from './pages/etc_config/EtcConfig.vue'
import MemberInfoDetail from './pages/admin/MemberInfoDetail.vue'
import CrawlingPage from './pages/crawl/CrawlingPage.vue'
import CrawlingDureItem from './pages/crawl/CrawlingDureItem.vue'

import store from './store';


const routes: readonly RouteRecordRaw[] = [
  { 
    path: '/LoginPage',
    component: LoginPage,
    name: 'LoginPage',
    meta: { layout: AuthFormLayout, requiresAuth: false } // 로그인 페이지는 인증이 필요하지 않음 
  },
  { 
    path: '/AdminMain',
    component: AdminMain,
    name: 'AdminMain',
    meta: { layout: MainLayout, requiresAuth: true, requiresAdmin: true } // 로그인 및 관리자 권한이 필요한 페이지
  },
  { 
    path: '/MngInout',
    component: MngInout,
    name: 'MngInout',
    meta: { layout: MainLayout, requiresAuth: true, requiresAdmin: true } // 로그인 및 관리자 권한이 필요한 페이지
  },
  { 
    path: '/MngBudgetDetailList',
    component: MngBudgetDetailList,
    name: 'MngBudgetDetailList',
    meta: { layout: BudgetLayout, requiresAuth: true, requiresAdmin: true } // 로그인 및 관리자 권한이 필요한 페이지
  },
  { 
    path: '/MngBudgetList',
    component: MngBudgetList,
    name: 'MngBudgetList',
    meta: { layout: BudgetLayout, requiresAuth: true, requiresAdmin: true } // 로그인 및 관리자 권한이 필요한 페이지
  },
  { 
    path: '/MngClosing',
    component: MngClosing,
    name: 'MngClosing',
    meta: { layout: ClosingLayout, requiresAuth: true, requiresAdmin: true } // 로그인 및 관리자 권한이 필요한 페이지
  },
  { 
    path: '/MngRptNewPage',
    component: MngRptNewPage,
    name: 'MngRptNewPage',
    meta: { layout: MngRptNewPage, requiresAuth: true, requiresAdmin: true } // 로그인 및 관리자 권한이 필요한 페이지
  },
  { 
    path: '/indexPage',
    component: indexPage,
    name: 'indexPage',
    meta: { layout: MainLayout , requiresAuth: true } // 로그인이 필요한 페이지
  },
  { 
    path: '/MemberList',
    component: MemberList,
    name: 'MemberList',
    meta: { layout: MainLayout , requiresAuth: true } // 로그인이 필요한 페이지
  },
  { 
    path: '/TestOne',
    component: TestOne,
    name: 'TestOne',
    meta: { layout: MainLayout , requiresAuth: true } // 로그인이 필요한 페이지
  },
  { 
    path: '/TestTwo',
    component: TestTwo,
    name: 'TestTwo',
    meta: { layout: MainLayout , requiresAuth: true } // 로그인이 필요한 페이지
  },
  { 
    path: '/EtcConfig',
    component: EtcConfig,
    name: 'EtcConfig',
    meta: { layout: MainLayout , requiresAuth: true } // 로그인이 필요한 페이지
  },
  { 
    path: '/adminmain/MemberInfoDetail',
    component: MemberInfoDetail,
    name: 'MemberInfoDetail',
    meta: { layout: MainLayout , requiresAuth: true } // 로그인이 필요한 페이지
  },
  { 
    path: '/CrawlingPage',
    component: CrawlingPage,
    name: 'CrawlingPage',
    meta: { layout: MainLayout, requiresAuth: true } //  로그인이 필요한 페이지
  },
  { 
    path: '/CrawlingDureItem',
    component: CrawlingDureItem,
    name: 'CrawlingDureItem',
    meta: { layout: MainLayout, requiresAuth: true } //  로그인이 필요한 페이지
  },
  {
    path: '/',
    redirect: '/indexPage',
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})


router.beforeEach((to, from, next) => {
  const isLoggedIn = store.getters.isLoggedIn;
  const isAdmin = store.getters.isAdmin;
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const requiresAdmin = to.matched.some(record => record.meta.requiresAdmin);

  if (requiresAuth && !isLoggedIn) {
    next('/LoginPage');
  } else if (requiresAdmin && !isAdmin) {
    alert('관리자 권한이 필요합니다.');
    next('/LoginPage');
  } else if (to.name === 'LoginPage' && isLoggedIn) {
    next('/indexPage');
  } else {
    next();
  }
});

export default router