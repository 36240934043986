<template>
  <v-dialog v-model="dialog" max-width="400" >
    <template v-slot:activator="{ props: activatorProps }">
      <v-btn color="indigo-lighten-2" v-bind="activatorProps" class="inoutExcelUsingBtn">조정</v-btn>
    </template>
    <v-card class="fontNotoSans400">
      <v-card-title class="inoutCtrlTitle pr-6 pl-6 pt-4">
        <b>{{form.memid}}</b>
        <v-icon v-if="!isLoading" class="closeIcon mt-1" @click="dialog = false" size="small">{{ mdiWindowClose }}</v-icon>
      </v-card-title>
      <v-card-text v-if="!isLoading">
        <v-row>
          <v-col>
            <v-text-field readonly v-model="form.biz_gubun" label="사업구분" variant="plain" density="compact" />
          </v-col>
          <v-col>
            <v-text-field readonly v-model="form.inout_date" label="날짜" variant="plain" density="compact" />
          </v-col>
        </v-row>
        <!-- <v-text-field v-model="form.name" label="계정조정인" variant="outlined" density="compact" /> -->
        <v-select
          v-model="form.gubun"
          :items="gubunValue"
          variant="outlined"
          color="indigo"
          label="구분"
          placeholder="- 선택 -"
          density="compact" 
        />
        <v-text-field v-model="form.memo" label="적요" variant="outlined" density="compact" clearable maxLength="19"/>

        <v-text-field
        v-model="form.price"
          label="금액" 
          variant="outlined"
          density="compact"
          @focus="clearFormat(form, 'price')"
          @blur="applyFormat(form, 'price')"
        />

        <v-combobox 
            v-if="form.gubun === '수입'"
            v-model="form.sname"
            label="재무계정" 
            :items="inNames" 
            variant="outlined" 
            density="compact" 
            class="activeClick"
            clearable
        ></v-combobox>
        <v-combobox 
            v-if="form.gubun === '지출'"
            v-model="form.sname"
            label="재무계정" 
            :items="outNames" 
            variant="outlined" 
            density="compact" 
            class="activeClick"
            clearable
        ></v-combobox>
        <v-combobox 
          v-if="form.gubun === '지출'"
          v-model="form.rel_acc"
          label="상대계정" 
          :items="filteredRelAccList" 
          variant="outlined" 
          density="compact" 
          class="activeClick"
          clearable
        ></v-combobox>
        <v-select
          v-model="form.funding"
          :items="fundingValue"
          variant="outlined"
          color="indigo"
          label="자금원천"
          placeholder="- 선택 -"
          density="compact" 
        />
        <v-select
          v-model="form.biz_name"
          :items="bizNameValue"
          variant="outlined"
          color="indigo"
          label="사업명"
          placeholder="- 선택 -"
          density="compact" 
        />

      </v-card-text>
      <div v-if="isLoading" style="height: 400px; display: flex; justify-content: center; align-items: center;">
        <b class="tac">저장 중 입니다.<br/>{{ message }}</b>
      </div>

      <v-btn v-if="!isLoading" @click="updateInoutData" color="#464646" style="font-size: 15px;">저장</v-btn>
    </v-card>
  </v-dialog>
</template>
<script setup lang="ts">
import { ref, reactive, defineProps, computed, watch, onMounted, onUnmounted } from 'vue';
import { mdiWindowClose } from '@mdi/js';
import store from '@/store';
import axios from "axios";

const message = ref("저장 중 입니다.");

const dots = ref("");
let interval: number | undefined;

const updateMessage = () => {
  if (dots.value.length >= 5) {
    dots.value = "";
  } else {
    dots.value += ".";
  }
  message.value = `잠시만 기다려주세요${dots.value}`;
};

onMounted(() => {
  interval = setInterval(updateMessage, 300); // 500ms 간격으로 점을 추가
});

onUnmounted(() => {
  clearInterval(interval); // 컴포넌트가 언마운트되면 인터벌 해제
});

const isLoading = ref(false);
// eslint-disable-next-line no-undef
const emit = defineEmits(['success']);

const props = defineProps({
  item: {
    type: Object,
    required: true,
  }
});
const form = reactive({
  name : store.getters.name,
  memid : store.state.mainComp,
  idx : props.item.idx,
  biz_gubun : props.item.biz_gubun,
  gubun : props.item.gubun,
  inout_date : props.item.inout_date,
  memo : props.item.memo,
  price : addCommaReal(props.item.price),
  sname : props.item.sname || null,
  rel_acc : props.item.rel_acc || null,
  before_rel_acc : props.item.rel_acc,
  funding : props.item.funding || null,
  biz_name : props.item.biz_name || null,
})
watch(() => props.item, (newValue) => {
  if (newValue) {
    form.memid = newValue.memid;
    form.biz_gubun = newValue.biz_gubun;
    form.gubun = newValue.gubun;
    form.inout_date = newValue.inout_date;
    form.memo = newValue.memo;
    form.price = addCommaReal(newValue.price);
    form.sname = newValue.sname || null;
    form.rel_acc = newValue.rel_acc || null;
    form.funding = newValue.funding || null;
    form.biz_name = newValue.biz_name || null;
  }
}, { deep: true }); // 깊은 감지를 원한다면 deep 옵션을 추가


const dialog = ref(false);
watch(dialog, (newVal) => {
  if (newVal) {
    resetForm();
  }
});

function resetForm() {
  form.name = store.getters.name;
  form.memid = store.state.mainComp;
  form.idx = props.item.idx;
  form.biz_gubun = props.item.biz_gubun;
  form.gubun = props.item.gubun;
  form.inout_date = props.item.inout_date;
  form.memo = props.item.memo;
  form.price = addCommaReal(props.item.price);
  form.sname = props.item.sname || null;
  form.rel_acc = props.item.rel_acc || null;
  form.before_rel_acc = props.item.rel_acc;
  form.funding = props.item.funding || null;
  form.biz_name = props.item.biz_name || null;
}
const inNames = ref([] as any);
const outNames = ref([] as any);

function clearFormat(item: any, key: any) {
  // 인풋의 값을 컴마 없이 만듭니다.
  item[key] = unformatNumber(item[key]);

  // 값이 0이면 빈 값으로 설정하여 placeholder를 보이도록 합니다.
  if (item[key] === '0' || item[key] === 0 || item[key] === '-0') {
    item[key] = '';
  }
}

function applyFormat(item: any, key: any) {
  // 값이 비어있으면 0으로 설정
  if (item[key] === '' || item[key] === null || item[key] === undefined || item[key] === '-0') {
    item[key] = 0;
  } else {
    // 포커스가 해제되면 천 단위로 컴마를 추가
    item[key] = formatNumber(item[key]);
  }
}

function formatNumber(value: any): string {
  // 숫자인지 확인하고 천 단위로 컴마 추가
  const number = parseFloat(value);
  if (!isNaN(number)) {
    return number.toLocaleString();
  }
  return value;
}

function unformatNumber(value: any) {
  // 컴마를 제거
  return value.toString().replace(/,/g, '');
}

function addCommaReal(number: number) {
  // 숫자가 아니거나 null인 경우 그대로 반환
  if (number === null || isNaN(number)) return number;

  // 숫자를 문자열로 변환 후 컴마 추가
  var strNumber = number.toLocaleString("en-US", { maximumFractionDigits: 2 });

  // 소수점 이하가 모두 0이 아닌 경우 처리
  var decimalIndex = strNumber.indexOf(".");
  if (decimalIndex !== -1) {
    var decimalPart = strNumber.substr(decimalIndex + 1);
    if (decimalPart !== "" && !/^0+$/.test(decimalPart)) {
      // 소수점 이하가 모두 0이 아닌 경우, 소수점 이하를 그대로 유지
      return strNumber;
    }
  }
  // 소수점 이하가 모두 0인 경우 또는 정수인 경우, 소수점 이하를 제거
  return strNumber
    .split(".")[0]
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
function nc(value: any) {
  // 컴마를 제거
  return parseFloat(value.toString().replace(/,/g, ''));
}
//괄호 수정 시, replace(/\([^()]*\)$/, '') 적용되어있는 mngcontroller 도 함께 수정요망
const relAccData = store.state.inoutDetailList.map((item :any) => ({
  sname: item.sname,
  difference: item.difference,
  scode: item.scode
}));
const relAccList = ref(relAccData.map(item => `${item.sname}${addComma(item.difference)}`));

const filteredRelAccList = computed(() => {
  if (form.sname === '생계비') {
    return relAccData
      .filter(item => item.sname === '식재료비수입' || item.sname === '직원식재료')
      .map(item => `${item.sname}${addComma(item.difference)}`);
  } else {
    return relAccList.value;
  }
});

// watch로 sname 변경 시 자동으로 rel_acc 설정
watch(() => form.sname, (newSname) => {
  if (newSname === '생계비') {
    // '생계비'가 선택되면, relAccList를 '식재료비수입'과 '직원식재료비수입'만 남기도록 필터링
    relAccList.value = relAccData
      .filter(item => item.sname === '식재료비수입' || item.sname === '직원식재료')
      .map(item => `${item.sname}${addComma(item.difference)}`);
    form.rel_acc = relAccList.value[0];
  } else if(newSname === '생계비(보조금)'){
    relAccList.value = relAccData
      .filter(item => item.sname === '시군구보조금')
      .map(item => `${item.sname}${addComma(item.difference)}`);
    form.rel_acc =relAccList.value[0];
  } else if(newSname === '의료비') {
    relAccList.value = relAccData
      .filter(item => item.sname === '기타비급여수입')
      .map(item => `${item.sname}${addComma(item.difference)}`);
    form.rel_acc = relAccList.value[0];
  } else {
    // 다른 값일 경우 전체 relAccList를 복원
    relAccList.value = relAccData.map(item => `${item.sname}${addComma(item.difference)}`);
    // form.rel_acc = null; // 기본값으로 초기화
  }
});


const gubunValue= [
  '수입',
  '지출',
]
const fundingValue = [
  '수익사업',
  '보조금',
  '자부담',
  '후원금'
]
const bizNameValue = [
  '일반사업',
  '생계비',
  '기능보강',
  '기타보조',
  '특별수당',
  '후원금',
  '주야간',
  '방문목욕',
  '방문요양'
]

if (form.gubun === '수입' && !isLoading.value) {
  watch(() => form.sname, async(newVal) => {
    if (newVal && inNames.value.includes(newVal)) {
      await getFundingAuto(newVal);
    }
  })
} else if (form.gubun === '지출' && !isLoading.value) {
  watch(() => form.rel_acc, async(newVal) => {
    if (newVal && relAccList.value.includes(newVal)) {
      await getFundingAuto(newVal);
    }
  })
}

function addComma(number:any) {
  // 입력이 숫자인지 확인 후 숫자로 강제 변환
  const num = Number(number);
  if (isNaN(num)) return number; // 숫자가 아닌 경우 그대로 반환
  if (num === 0) return ""; // 0이 입력된 경우 "-" 반환
  // 숫자인 경우 컴마를 추가한 문자열 반환
  return ` / ${num.toLocaleString()}`;
}
let startDate = ref(store.state.mainStartDate);
const setMainStartDate = computed(() => {
  const mainStartDateComputed = store.state.mainStartDate;
  return mainStartDateComputed;
});

watch(setMainStartDate, async (newValue: any) => {
  if (newValue) {
    startDate.value = newValue;
  }
});
const allComCode = ref([] as any)
 const getAllComCode = async () => {
  try {
    const response = await axios.get(`/api/getAllComCode/${startDate.value}`); 
    const responseData = response.data;
    if (responseData) {
      allComCode.value = responseData;
      const inData = responseData.filter((item: { trcode: string }) => item.trcode === 'IN');
      const outData = responseData.filter((item: { trcode: string }) => item.trcode === 'OUT');
      inNames.value = inData.map((item: { sname: any; scode: any; }) => `${item.sname}`);
      outNames.value = outData.map((item: { sname: any; scode: any; }) => `${item.sname}`);
      return responseData;
    } else {
      console.error('에러가 발생했습니다.', responseData);
    }
  } catch (error) {
    console.error('errorMsg:', error);
  }
};
function isNumeric(value: any): boolean {
  return !isNaN(Number(value));
}
const getFundingAuto = async (getItem: string) => {
  if(isNumeric(getItem)){
    return;
  }
    try {
    const response = await axios.get(`/api/getFundingAuto/${splash(getItem)}/${startDate.value}`); 
    const responseData = response.data;
    if (responseData) {
      form.funding = responseData;
      return responseData;
    } else {
      console.error('에러가 발생했습니다.', responseData);
    }
  } catch (error) {
    console.error('errorMsg:', error);
  }
};
function splash(input: any) {
  // ' / '를 기준으로 문자열을 나누고 첫 번째 부분만 반환, 없으면 input 그대로 반환
  return input?.split(' / ')[0] || input;
}

function getScode(sname: string, gubun : string) {
  const item = allComCode.value.find((item: any) => item.sname === sname && item.trcode === gubun) as any;
  console.log(item)

  return item ? item.scode : null;
}


const updateInoutData = async () => {
  if (form.gubun) {
    if (!form.price || form.price === 0) {
      alert(`금액이 입력되지 않았습니다.`);
      return;
    } else if (!form.sname) {
      alert(`계정이 입력되지 않았습니다.`);
      return;
    } else if (!form.rel_acc && form.gubun === '지출') {
      alert(`상대계정이 입력되지 않았습니다.`);
      return;
    } else if (!form.memo) {
      alert(`적요가 입력되지 않았습니다.`);
      return;
    } else if (!form.funding) {
      alert(`자금원천이 입력되지 않았습니다.`);
      return;
    } else if (!form.biz_name) {
      alert(`사업명이 입력되지 않았습니다.`);
      return;
    }
  } else {
    alert(`구분이 입력되지 않았습니다.`);
    isLoading.value = false;
    return;
  }

  isLoading.value = true;
  try {
    if(form.funding === '수익사업'){
      form.funding = '06';
    }else if(form.funding === '보조금'){
      form.funding = '07';
    }else if(form.funding === '자부담'){
      form.funding = '04';
    }else if(form.funding === '후원금'){
      form.funding = '05';
    }
    if(form.gubun === '수입'){
      form.gubun = 'IN';
      form.rel_acc = '';
    }else if(form.gubun === '지출'){
      form.gubun = 'OUT';
    }
    form.price = nc(form.price);
    form.sname = getScode(form.sname, form.gubun);
    form.rel_acc = getScode(splash(form.rel_acc), 'IN');
    const response = await axios.post(`/api/updateInoutData`, {form: form});
    const result = response.data
    if (result.success) {
    dialog.value = false;
    }
  }
  catch (error) {
    console.error(error);
    throw error; // 에러 다시 던지기
  }finally{
    emit('success');
    dialog.value = false;
    isLoading.value = false;
  }
};
onMounted( async() => {
  await getAllComCode();
})


</script>
<style scoped>
  .inoutCtrlTitle{
  align-items: center;
  justify-content: center;
    font-size: 17px;
    border-bottom: 2px solid #969696;
  }
</style>