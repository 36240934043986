<template>
      <v-btn @click="logout" :append-icon="mdiLogoutVariant">
          Logout
        </v-btn>
</template>
<script setup>
import { ref } from 'vue';

import {
  mdiLogoutVariant,
} from '@mdi/js';
import axios from 'axios';
const account = ref({
  id: null,
  name: '',
  role: '',
});
const logout = () => {
  axios.delete("/api/account").then(() => {
    alert("로그아웃하였습니다.");
    account.value.id = null;
    account.value.name = "";
    account.value.role = "";
    sessionStorage.removeItem('vuex_state');
    sessionStorage.removeItem('crawl_Result_List');
    sessionStorage.removeItem('crawl_Dure_Item');
    sessionStorage.removeItem('mainDateInfo');
    sessionStorage.removeItem('mainCompInfo');
    sessionStorage.removeItem('mainCompBizGubun');
    sessionStorage.removeItem('selected_member');
    sessionStorage.removeItem('mainStartDateInfo');
    sessionStorage.removeItem('mainEndDateInfo');
    sessionStorage.removeItem('inoutDetailList');
    sessionStorage.removeItem('ClosingStartMonth');
    sessionStorage.removeItem('ClosingEndMonth');

    window.location.reload();
  });
};
</script>