<template>
  <v-data-table
    fixed-header
    density="compact"
    scrollable
    :headers="headersArr"
    :items="compInfoListArr"
    item-value="name"
    class="memberListTb fontNotoSans400"
    style="
      max-height: calc(100vh - 97px);
      height: calc(100vh - 97px);
      overflow-y: hidden;
      position: relative;
    "
    :items-per-page="200"
    :items-per-page-options="[
      { value: 50, title: '50' },
      { value: 100, title: '100' },
      { value: 200, title: '200' },
      { value: -1, title: '$vuetify.dataFooter.itemsPerPageAll' },
    ]"
  >
    <template v-slot:item="{ item, index }">
      <tr v-if="!store.state.mainComp || `${item.mb_name}(${item.mb_id})` === store.state.mainComp">
        <td class="tac">{{ index + 1 }}</td>
        <td class="tac">
          <p style="font-weight: 600">{{ item.first_word }}</p>
          <p>{{ item.mb_name }}</p>
          <p>({{ item.mb_id }})</p>
        </td>
        <td class="tac">
          <!-- 요양시설이 item.sisul에 포함되었으면 표시 -->
          <p class="mainSisulBizGubun" v-if="item.sisul.includes('요양시설')">
            요양시설:
            <span
              class="mainService"
              v-for="(val, index) in item.요양시설
                ? item.요양시설.split('/')
                : [item.요양시설]"
              :key="'요양시설-' + index"
              :style="getBackgroundColor(val)"
            >
              <span v-text="val"></span>
              <span
                v-if="index < (item.요양시설 ? item.요양시설.split('/').length : 1) - 1"
              >
              </span>
            </span>
          </p>

          <!-- 주야간보호가 item.sisul에 포함되었으면 표시 -->
          <p class="mainSisulBizGubun" v-if="item.sisul.includes('주야간보호')">
            주야간보호:
            <span
              class="mainService"
              v-for="(val, index) in item.주야간보호
                ? item.주야간보호.split('/')
                : [item.주야간보호]"
              :key="'주야간보호-' + index"
              :style="getBackgroundColor(val)"
            >
              <span v-text="val"></span>
              <span
                v-if="
                  index < (item.주야간보호 ? item.주야간보호.split('/').length : 1) - 1
                "
              >
              </span>
            </span>
          </p>

          <!-- 방문요양이 item.sisul에 포함되었으면 표시 -->
          <p class="mainSisulBizGubun" v-if="item.sisul.includes('방문요양')">
            방문요양:
            <span
              class="mainService"
              v-for="(val, index) in item.방문요양
                ? item.방문요양.split('/')
                : [item.방문요양]"
              :key="'방문요양-' + index"
              :style="getBackgroundColor(val)"
            >
              <span v-text="val"></span>
              <span
                v-if="index < (item.방문요양 ? item.방문요양.split('/').length : 1) - 1"
              >
              </span>
            </span>
          </p>

          <!-- 방문목욕이 item.sisul에 포함되었으면 표시 -->
          <p class="mainSisulBizGubun" v-if="item.sisul.includes('방문목욕')">
            방문목욕:
            <span
              class="mainService"
              v-for="(val, index) in item.방문목욕
                ? item.방문목욕.split('/')
                : [item.방문목욕]"
              :key="'방문목욕-' + index"
              :style="getBackgroundColor(val)"
            >
              <span v-text="val"></span>
              <span
                v-if="index < (item.방문목욕 ? item.방문목욕.split('/').length : 1) - 1"
              >
              </span>
            </span>
          </p>

          <!-- 방문간호가 item.sisul에 포함되었으면 표시 -->
          <p class="mainSisulBizGubun" v-if="item.sisul.includes('방문간호')">
            방문간호:
            <span
              class="mainService"
              v-for="(val, index) in item.방문간호
                ? item.방문간호.split('/')
                : [item.방문간호]"
              :key="'방문간호-' + index"
              :style="getBackgroundColor(val)"
            >
              <span v-text="val"></span>
              <span
                v-if="index < (item.방문간호 ? item.방문간호.split('/').length : 1) - 1"
              >
              </span>
            </span>
          </p>

          <!-- 복지용구용품이 item.sisul에 포함되었으면 표시 -->
          <p class="mainSisulBizGubun" v-if="item.sisul.includes('복지용구용품')">
            복지용구용품:
            <span
              class="mainService"
              v-for="(val, index) in item.복지용구용품
                ? item.복지용구용품.split('/')
                : [item.복지용구용품]"
              :key="'복지용구용품-' + index"
              :style="getBackgroundColor(val)"
            >
              <span v-text="val"></span>
              <span
                v-if="
                  index <
                  (item.복지용구용품 ? item.복지용구용품.split('/').length : 1) - 1
                "
              >
              </span>
            </span>
          </p>
          <p class="mainSisulBizGubun" v-if="item.sisul.includes('공동생활가정')">
            공동생활가정:
            <span
              class="mainService"
              v-for="(val, index) in item.공동생활가정
                ? item.공동생활가정.split('/')
                : [item.공동생활가정]"
              :key="'공동생활가정-' + index"
              :style="getBackgroundColor(val)"
            >
              <span v-text="val"></span>
              <span
                v-if="index < (item.공동생활가정 ? item.공동생활가정.split('/').length : 1) - 1"
              >
              </span>
            </span>
          </p>

          <!-- 급여 항목은 항상 표시 -->
          <span class="mainService tac" :style="getBackgroundColor(item.급여)">
            <span>{{ item.급여 }}</span>
          </span>
        </td>
        <td class="tac">
          <button type="button" class="mainRouteBtn" @click="clickCompInfo(item.mb_name,item.mb_id, 'inout')">현금출납부</button>
          <button type="button" class="mainRouteBtn" @click="clickCompInfo(item.mb_name,item.mb_id, 'budget')">예산</button>
          <button type="button" class="mainRouteBtn" @click="clickCompInfo(item.mb_name,item.mb_id, 'closing')">결산</button>
        </td>
        <td class="tac">
          <p>사업자번호: {{ item.mb_regnb }}</p>
          <p>시설팩스 : {{ item.mb_fax }}</p>
          <p>시설전화 : {{ item.mb_phone }}</p>
          <p>시설메일 : {{ item.mb_email }}</p>
        </td>
        <td class="tac">
          <p>{{ item.head_name }}</p>
          <p>{{ item.head_mbphone }}</p>
        </td>
        <td class="tac">
          <p>{{ item.chief_name }}</p>
          <p>{{ item.chief_mbphone }}</p>
          <p>{{ item.chief_hmphone }}</p>
        </td>
        <td class="tac">
          <p>{{ item.paym_name }}</p>
          <p>{{ item.paym_mbphone }}</p>
          <p>{{ item.paym_hmphone }}</p>
        </td>
       

      </tr>
    </template>
  </v-data-table>
</template>
<script setup lang="ts">
import router from "@/router";
import store from "@/store";
import axios from "axios";
import { computed, onMounted, ref, watch } from "vue";
const isLoading = ref(false);
const compInfoListArr = ref([] as any[]);
function formatMonth(inputDate: any) {
  const date = new Date(inputDate);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  return `${year}-${month}`;
}
const headersArr = ref([
  { title: "No.", key: "index", sortable: false },
  { title: "시설", key: "mb_name", align: "center", sortable: true },
  { title: "서비스", key: "sisul", align: "center", sortable: true },
  { title: "기능", key: "tool", align: "center", sortable: false },
  { title: "시설정보", key: "sisul_phone", align: "center", sortable: false },
  { title: "대표자", key: "head_name", align: "center", sortable: true },
  { title: "시설장", key: "chief_name", align: "center", sortable: true },
  { title: "회계담당자", key: "paym_name", align: "center", sortable: true },
] as any[]);
const getMemberList = async () => {
  isLoading.value = true;

  try {
    console.log(
      formatMonth(store.state.mainStartDate),
      "formatMonth(store.state.mainStartDate)"
    );
    const response = await axios.get(
      `/api/getMemberList/${formatMonth(store.state.mainStartDate)}/${
        store.state.MainAdminName
      }`
    );
    const responseData = response.data;

    if (Array.isArray(responseData)) {
      // compNames.value = responseData.map(item => item.mb_name); // mb_name만 추출하여 names 배열에 저장
      // compNames.value = responseData.map(item => `${item.mb_name}(${item.memid})`);
      compInfoListArr.value = responseData;
      return responseData; // 가져온 데이터를 반환
    } else {
      console.error("에러가 발생했습니다.", responseData);
    }
  } catch (error) {
    console.error("errorMsg:", error);
  } finally {
    isLoading.value = false;
  }
};
const getBackgroundColor = (val: any) => {
  switch (val) {
    case "입":
      return {
        backgroundColor: "#6c757d",
        color: "white",
        padding: "1px 4px",
        borderRadius: "3px",
        marginLeft: "5px",
      };
    case "조":
      return {
        backgroundColor: "#28b779",
        color: "white",
        padding: "1px 4px",
        borderRadius: "3px",
        marginLeft: "5px",
      };
    case "출":
      return {
        backgroundColor: "#da542e",
        color: "white",
        padding: "1px 4px",
        borderRadius: "3px",
        marginLeft: "5px",
      };
    case "급":
      return {
        backgroundColor: "#ffb848",
        color: "black",
        padding: "1px 4px",
        borderRadius: "3px",
      };
    default:
      return {};
  }
};

const clickCompInfo = async(compName: string, compId: string, kindof: string) => {
  if(compName && compId && kindof){
    store.commit('setMainComp', `${compName}(${compId})`);
    if(kindof === 'inout'){
      await router.push('/MngInout');
    }else if(kindof === 'budget'){
      await router.push('/mngbudgetlist');
    }else if(kindof === 'closing'){
      await router.push('/MngClosing');
    }
  }

}

const setMainStartDate = computed(() => {
  const mainStartDateComputed = store.state.mainStartDate;
  return mainStartDateComputed;
});

watch(setMainStartDate, async (newValue: any) => {
  if (newValue) {
    await getMemberList();
  }
});
onMounted(async () => {
  sessionStorage.removeItem("selected_member");
  await getMemberList();
});
</script>
