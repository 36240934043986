<template>
  <div v-if="isLoading" class="loading-spinner"></div>
  <v-card class="table-container-closing" v-else>
    <table class="budDetailListTable">
      <thead>
        <tr>
          <th>구분</th>
          <th>관</th>
          <th>항</th>
          <th>목</th>
          <th>구분</th>
          <th>보조금</th>
          <th>수익자부</th>
          <th>후원금</th>
          <th>계</th>
        </tr>
      </thead>
      <tbody>
        <template v-if="mainGubun === '전체' || mainGubun === '세입'">
          <tr class="budListTr" v-for="(item, index) in inDataList" :key="index">
            <!-- Gubun -->
            <td
              class="tac defaultBgColor"
              v-if="item.rowspan.gubun"
              :rowspan="item.rowspan.gubun"
            >
              {{ item.gubun }}
            </td>

            <!-- 관 -->
            <td
              class="tac defaultBgColor"
              v-if="item.rowspan.관"
              :rowspan="item.rowspan.관"
            >
              {{ item.관 }}<br />{{ item.lcode }}
            </td>

            <!-- 항 -->
            <td
              class="tac defaultBgColor"
              v-if="item.rowspan.항"
              :rowspan="item.rowspan.항"
            >
              {{ item.항 }}<br />{{ item.mcode }}
            </td>

            <!-- 목 -->
            <td
              class="tac inMok pointer"
              v-if="item.rowspan.목"
              :rowspan="item.rowspan.목"
            >
              {{ item.목 }}<br />{{ item.scode }}
            </td>

            <td class="tac inMok pointer">{{ item.source }}</td>
            <td class="tar inMok pointer">{{ item.보조금 }}</td>
            <td class="tar inMok pointer">{{ item.수익자부 }}</td>
            <td class="tar inMok pointer">{{ item.후원금 }}</td>
            <td class="tar inMok pointer">{{ item.총액 }}</td>
          </tr>
        </template>

        <template v-if="mainGubun === '전체' || mainGubun === '세출'">
          <tr class="budListTr" v-for="(item, index) in outDataList" :key="index">
            <!-- Gubun -->
            <td
              class="tac defaultBgColor"
              v-if="item.rowspan.gubun"
              :rowspan="item.rowspan.gubun"
            >
              {{ item.gubun }}
            </td>

            <!-- 관 -->
            <td
              class="tac defaultBgColor"
              v-if="item.rowspan.관"
              :rowspan="item.rowspan.관"
            >
              {{ item.관 }}<br />{{ item.lcode }}
            </td>

            <!-- 항 -->
            <td
              class="tac defaultBgColor"
              v-if="item.rowspan.항"
              :rowspan="item.rowspan.항"
            >
              {{ item.항 }}<br />{{ item.mcode }}
            </td>

            <!-- 목 -->
            <td
              class="tac inMok pointer"
              v-if="item.rowspan.목"
              :rowspan="item.rowspan.목"
            >
              {{ item.목 }}<br />{{ item.scode }}
            </td>

            <td class="tac inMok pointer">{{ item.source }}</td>
            <td class="tar inMok pointer">{{ item.보조금 }}</td>
            <td class="tar inMok pointer">{{ item.수익자부 }}</td>
            <td class="tar inMok pointer">{{ item.후원금 }}</td>
            <td class="tar inMok pointer">{{ item.총액 }}</td>
          </tr>
        </template>
      </tbody>
    </table>
  </v-card>
</template>

<script setup lang="ts">
import store from "@/store";
import axios from "axios";
import { onMounted, ref, watch } from "vue";

const mainComp = ref(null) as any;
const mainCompBizGubun = ref(null) as any;
const mainYear = ref(null) as any;
const budNum = ref(null) as any;
const mainGubun = ref(null) as any;
const mainStartMonth = ref(null) as any;
const mainEndMonth = ref(null) as any;
const isLoading = ref(false) as any;

const closeList = ref([]) as any;
const inDataList = ref([]) as any;
const outDataList = ref([]) as any;

const getCloseList = async () => {
  try {
    const response = await axios.get(
      `/api/getCloseList/${mainComp.value}/${mainCompBizGubun.value}/${mainYear.value}/${mainStartMonth.value}/${mainEndMonth.value}`
    );
    const responseData = response.data;
    if (responseData) {
      closeList.value = responseData;
      inDataList.value = closeList.value.filter(
        (item: { gubun: string }) => item.gubun === "세입"
      );
      inDataList.value = calculateRowspan(inDataList.value);
      outDataList.value = closeList.value.filter(
        (item: { gubun: string }) => item.gubun === "세출"
      );
      outDataList.value = calculateRowspan(outDataList.value);
    }
    return responseData;
  } catch (error) {
    console.error("errorMsg:", error);
  }
};
const calculateRowspan = (list: any[]) => {
  const updatedList = list.map((item) => ({
    ...item,
    rowspan: { gubun: 1, 관: 1, 항: 1, 목: 1 },
  }));

  let gubunRowspan = 1,
    관Rowspan = 1,
    항Rowspan = 1,
    목Rowspan = 1;

  // 항목들에 대해 순차적으로 rowspan을 적용
  for (let i = 0; i < updatedList.length; i++) {
    const current = updatedList[i];
    const prev = updatedList[i - 1];

    // 같은 gubun에 대해서 rowspan을 적용
    if (prev && prev.gubun === current.gubun) {
      gubunRowspan++;
      current.rowspan.gubun = 0;
    } else {
      if (i > 0) updatedList[i - gubunRowspan].rowspan.gubun = gubunRowspan;
      gubunRowspan = 1;
    }

    // 같은 관에 대해서 rowspan을 적용
    if (prev && prev.관 === current.관 && prev.gubun === current.gubun) {
      관Rowspan++;
      current.rowspan.관 = 0;
    } else {
      if (i > 0) updatedList[i - 관Rowspan].rowspan.관 = 관Rowspan;
      관Rowspan = 1;
    }

    // 같은 항에 대해서 rowspan을 적용
    if (
      prev &&
      prev.항 === current.항 &&
      prev.관 === current.관 &&
      prev.gubun === current.gubun
    ) {
      항Rowspan++;
      current.rowspan.항 = 0;
    } else {
      if (i > 0) updatedList[i - 항Rowspan].rowspan.항 = 항Rowspan;
      항Rowspan = 1;
    }

    // 같은 목에 대해서 rowspan을 적용
    if (
      prev &&
      prev.목 === current.목 &&
      prev.항 === current.항 &&
      prev.관 === current.관 &&
      prev.gubun === current.gubun
    ) {
      목Rowspan++;
      current.rowspan.목 = 0;
    } else {
      if (i > 0) updatedList[i - 목Rowspan].rowspan.목 = 목Rowspan;
      목Rowspan = 1;
    }
  }

  // 마지막 그룹에 대해 rowspan 설정
  if (updatedList.length > 0) {
    updatedList[updatedList.length - gubunRowspan].rowspan.gubun = gubunRowspan;
    updatedList[updatedList.length - 관Rowspan].rowspan.관 = 관Rowspan;
    updatedList[updatedList.length - 항Rowspan].rowspan.항 = 항Rowspan;
    updatedList[updatedList.length - 목Rowspan].rowspan.목 = 목Rowspan;
  }

  return updatedList;
};

watch(
  () => [
    store.state.mainComp,
    store.state.mainCompBizGubun,
    store.state.mainYear,
    store.state.mainBudNum,
    store.state.mainGubun,
    store.state.ClosingStartMonth,
    store.state.ClosingEndMonth,
  ],
  async ([
    newMainComp,
    newMainCompBizGubun,
    newMainYear,
    newBudNum,
    newMainGubun,
    newClosingStartMonth,
    newClosingEndMonth,
  ]) => {
    mainComp.value = newMainComp;
    mainCompBizGubun.value = newMainCompBizGubun;
    mainYear.value = newMainYear;
    budNum.value = newBudNum;
    mainGubun.value = newMainGubun;
    mainStartMonth.value = newClosingStartMonth;
    mainEndMonth.value = newClosingEndMonth;
    isLoading.value = true;
    await getCloseList();
    isLoading.value = false;
  }
);
onMounted(async () => {
  mainComp.value = store.state.mainComp;
  mainCompBizGubun.value = store.state.mainCompBizGubun;
  mainYear.value = store.state.mainYear;
  budNum.value = store.state.mainBudNum;
  mainGubun.value = store.state.mainGubun;
  mainStartMonth.value = store.state.ClosingStartMonth;
  mainEndMonth.value = store.state.ClosingEndMonth;
  isLoading.value = true;
  await getCloseList();
  isLoading.value = false;
});
</script>
<style>
.table-container-closing {
  max-height: calc(100vh - 98px); /* 원하는 최대 높이 설정 */
  overflow-y: auto; /* Y축 스크롤 활성화 */
  overflow-x: auto; /* Y축 스크롤 활성화 */
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  border: 1px solid #ccc; /* 테이블 테두리 */
}
</style>
